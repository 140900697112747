<template src="./camTestRoom.html"></template>

<script>

import mixins from "../../../mixins/mixins.js";
import { isSupported } from "twilio-video"
import { testVideoInputDevice, VideoInputTest, testAudioInputDevice, AudioInputTest, testMediaConnectionBitrate } from "@twilio/rtc-diagnostics";

export default {
  mixins: [mixins],
  components: {
  },
  data() {
    return {
      video: undefined,
      hasMic: undefined,
      hasVideo: undefined,
      hasAudio: false,
      hasConnection: undefined,
      supportsTouch: undefined,
      max: 100,
      micInputLevel: 0,
      cameraStatus: "",
      micStatus: "",
      testReportObject: [],
    };
  },
  created: function () {
    this.supportsTouch = "ontouchstart" in window || navigator.msMaxTouchPoints;
    console.log("isSupported:", isSupported);
  },
  mounted: function () {
    this.video = document.getElementById("video");
  },
  beforeRouteLeave(to, from, next) {
    this.releaseCam();
    next();
  },
  methods: {
    returnPage(){
      const vm = this;
      this.releaseCam();
      vm.$router.push({ name: "scheduled" });
    },
    releaseCam() {
      if (!this.testNullUndefinedEmpty(this.video.srcObject)){
        this.video.srcObject.getTracks().forEach(function(track) {
          if (track.readyState === "live") {
            track.stop();
          }
        })
      }
    },
    nextBtn(){
      this.$router.push("/waiting-room/" + this.$route.params.medRecId);
    },
    testBtn() {
      var vm = this;
      this.hasMic = undefined;
      this.hasVideo = undefined;
      this.hasConnection = undefined;
      this.testMediaConnectionBitrateAuthenticated();
      this.testAudioInputDevice();
      this.testVideoInputDevice();
      setTimeout(() => {
        this.postReport(vm.testReportObject)
      }, 12000);
    },

    testMediaConnectionBitrateAuthenticated() {
      const vm = this;
      vm.axios
        .get(vm.$store.state.filooServer + "appointments/videoTestToken", {
          headers: {Authorization: vm.$session.get("bearer")},
        })
        .then(function (response) {
          let validCredential;
          if (response.status === 200) {
            const servers = response.data.servers;
            servers.forEach(element => {
              if(element.credential != null && element.username != null) {
                validCredential = element;
              }
            });
            vm.testMediaConnectionBitrate(validCredential.credential, validCredential.username, validCredential.url);
          }
        })
        .catch(function (error){
          console.log(error);
        })
    },
    testMediaConnectionBitrate(credential, username, url) {
      var vm = this;
      console.log("testMediaConnectionBitrate: ")
      const mediaConnectionBitrateTest = testMediaConnectionBitrate({
        iceServers: [{
          credential: credential,
          username: username,
          urls: url,
        }],
      });

      mediaConnectionBitrateTest.on("bitrate", (bitrate) => {
        console.log(bitrate);
      });

      mediaConnectionBitrateTest.on("error", (error) => {
        vm.testReportObject.push(error);
        console.log(error);
      });

      mediaConnectionBitrateTest.on("end", (report) => {
        console.log(report);
        vm.testReportObject.push(report);
        if(!report.errors.length) {
          vm.hasConnection = true;
        }
      });

      setTimeout(() => {
        mediaConnectionBitrateTest.stop();
      }, 10000);
    },

    testAudioInputDevice() {
      var vm = this;
      const audioInputDeviceTest = testAudioInputDevice({
        audio: true,
      });
      audioInputDeviceTest.on(AudioInputTest.Events.Volume, (volume) => {
        vm.micInputLevel = volume;
      });
      audioInputDeviceTest.on(AudioInputTest.Events.Error, (error) => {
        vm.hasMic = false;
        vm.testReportObject.push(error);
      });
      audioInputDeviceTest.on(AudioInputTest.Events.End, (report) => {
        vm.testReportObject.push(report);
        if(!report.errors.length) {
          vm.hasMic = true;
        }
      });
      setTimeout(() => {
        audioInputDeviceTest.stop();
      }, 10000);
    },

    testVideoInputDevice() {
      var vm = this;
      const videoInputDeviceTest = testVideoInputDevice({ element: this.video });
      videoInputDeviceTest.on(VideoInputTest.Events.Error, (error) => {
        vm.testReportObject.push(error);
        vm.hasVideo = false;
      });
      videoInputDeviceTest.on(VideoInputTest.Events.End, (report) => {
        if(!report.errors.length) {
          vm.hasVideo = true;
          vm.testReportObject.push(report);
        }
        vm.tryCameraAgain();
      });
      setTimeout(() => {
        videoInputDeviceTest.stop();
      }, 10000);
    },
    tryCameraAgain() {
      var vm = this;
      navigator.mediaDevices.getUserMedia({video: true})
        .then(function(stream){
          console.log("stream: ", stream)
          vm.video.srcObject = stream;
          vm.video.play();
        })
        .catch((err) => {
          console.log(err.name)
          console.log(err.message)
          if(err.name === "NotAllowedError" || err.name === "PermissionDeniedError"){  //  Permission dismissed
            console.log("Voce negou acesso a camera")
            if (vm.supportsTouch) {
              console.log("mostra modal acesso a camera mobile")
              vm.$bvModal.show("modal-cam-chrome-mobile");
            } else {
              console.log("mostra modal acesso a camera desktop")
              vm.$bvModal.show("modal-cam-chrome");
            }
          }
          if(err.name === "NotFoundError"){  //  Requested device not found
            this.$bvModal.show("modal-camera-not-found")
          }
          throw (new Error("The message"));
        });
    },
    postReport(report) {
      var vm = this;
      vm.axios
        .post(vm.$store.state.filooServer + "appointments/videoTestResult", report, {
          headers: { Authorization: vm.$session.get("bearer") },
        })
        .then(function () {
        })
        .catch(function () {
        })
    },
  },
  // https://www.twilio.com/blog/voice-diagnostics-web-app-rtc-diagnostics-sdk-beta https://github.com/twilio/rtc-diagnostics/issues/65   https://www.twilio.com/docs/stun-turn/api#

};
</script>

<style lang="scss" src="./camTestRoom.scss">
</style>
